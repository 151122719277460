#root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.main {
    display: flow-root;
    flex: 1;
}

@media screen and (min-width: 1024px) {
    .container {
        max-width: 1110px;
    }
    .navbar-start {
        justify-content: flex-end;
        margin-right: unset;
        flex-grow: 1;
    }
    .navbar-item img {
        max-height: none !important;
    }
    .navbar-item.is-active .navbar-dropdown.is-boxed {
        opacity: 1;
        pointer-events: auto;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@media screen and (min-width: 769px){
    .modal-card {
        width: 650px;
    }
    .container.login {
        margin-top: 0;
    }
}

.container.login {
    margin-top: 10vh;
}

.navbar-burger {
    height: auto;
}

.navbar-item img {
    max-height: 2rem;
}

.footer, .footer a {
    background: #222222;
    color: #bbbbbb;
    font-size: 14px;
    z-index: 30;
    padding: 20px 0px;
    text-align: center;
    font-weight: 500;
}

.footer a {
    color: #DDDDDD;
}

.footer a:hover {
    text-decoration: underline;
    color: #DDDDDD;
}

.iframe {
    display: none;
}

.fontTitle {
    font-family: 'Raleway', sans-serif;
}

.nombreEmpresa {
    padding-bottom: 0.6rem;
    font-weight: 700;
}

.loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    opacity: 0;
    z-index: -1;
    transition: opacity .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}

.loader-wrapper.is-active {
    opacity: 1;
    z-index: 1;
}

.loader-wrapper > .loader {
    height: 80px;
    width: 80px;
}

.navbar-menu.is-active {
    position: absolute;
    width: 100%;
}

.dropdown-menu {
    min-width: initial;
}

.dropdown-content.scrollable {
    max-height: 13em;
    overflow: auto;
}

.dropdown-item {
    cursor: pointer;
}

.button.is-primary:hover {
    background-color: #000000;
}

a.navbar-item.is-active {
    font-weight: 700;
}

a.navbar-item:hover {
    color: #003da6 !important;
}

.modal-card-foot {
    justify-content: flex-end
}

.input.withBorder, .textarea.withBorder {
    border-color: #b5b5b5;
}

.input.textCentered {
    text-align: center;
}

.modal .overflowVisible {
    overflow: visible;
}

.modal-card {
    max-width: 100%;
}

.button.minWidthEjercicio {
    min-width: 115.5px;
}

.button.minWidthSemestre {
    min-width: 157.5px;
}

.button.minWidthTrimestre {
    min-width: 159px;
}

.button.minWidthMes {
    min-width: 140.5px;
}

.navbar-item.minWidth96 {
    min-width: 96px;
}

.navbar-item.minWidth76 {
    min-width: 76.5px;
}

.navbar-item.minWidth93 {
    min-width: 93px;
}

.navbar-item.minWidth122 {
    min-width: 122px;
}

.text-nowrap {
    white-space: nowrap;
}

.table td {
    border: 0;
}

.table thead th {
    white-space: nowrap;
}

.table tbody tr:not(.noHover):hover {
    background-color: rgba(128, 128,128, 0.35);
}

.table thead tr {
    background-color: rgba(128, 128,128, 0.5);
}

.table tbody tr.tableTitle {
    background-color: #e6e6e6;
}

.table tbody tr.tableTitle td {
    font-weight: 700
}

.table tbody tr.final {
    background-color: rgba(128, 128,128, 0.5);
}

.table tbody tr.final td {
    font-weight: 700
}

.table tbody tr td.bold {
    font-weight: 700
}

.table tbody tr.selectedDocument {
    background-color: #e6e6e6;
}

.table tbody tr td.download {
    cursor: pointer;
}

@media screen and (min-width: 1024px) {
    .minWidth240 {
        min-width: 240px;
    }
}

.notificationAbsolute {
    position: absolute;
    z-index: 1;
    right: 10px;
    padding-left: 10px;
    top: 4.5rem;
}
